<template>
  <section>
    <p class="text-h6 font-museo-sans mb-0 secondary--text">
      Certificación de fondos
    </p>
    <v-row class="mt-4">
      <div class="d-flex align-center">
        <v-btn
          class="flex-grow-1 flex-shrink-1 ml-4 btn-text"
          color="primary"
          x-large
          :to="{ name: 'certificacion-fondos-catalogo' }"
        >
          <v-icon class="white--text ml-2">mdi-file-certificate</v-icon>
          Certificación catálogo electrónico
        </v-btn>
      </div>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12" sm="6">
        <v-text-field
          label="Código / No. de proceso"
          outlined
          clearable
          @input="getCertificacionesFondosFilter()"
          v-model="search.codigo_proceso"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          label="Modalidad de compra"
          outlined
          item-text="nombre"
          item-value="id"
          clearable
          @change="getCertificacionesFondosFilter()"
          :items="modalidades"
          v-model="search.forma_contratacion"
        ></v-select>
      </v-col>
    </v-row>

    <data-table-component
      :headers="headers"
      :items="certificaciones"
      :total_registros="filters.total_rows"
      v-models:select="perPageModel"
      v-models:pagina="pageModel"
      @paginar="paginar"
    >
      <template #[`item.fecha_solicitado`]="{ item }">
        {{ moment(item.fecha_solicitado).format("DD/MM/YYYY") }}
      </template>
      <template #[`item.monto_programado`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          }).format(item.monto_programado)
        }}
      </template>
      <template #[`item.monto_certificado`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          }).format(item.monto_certificado)
        }}
        <v-icon :color="getStatusColor(item)">mdi-circle</v-icon>
      </template>
      <template #[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="verDetalleProceso(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Ver detalle proceso</span>
        </v-tooltip>
      </template>
    </data-table-component>
  </section>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
export default {
  components: { DataTableComponent },
  data: () => ({
    headers: [
      {
        text: "Código",
        value: "codigo_proceso",
        align: "center",
      },
      {
        text: "Proceso",
        value: "nombre_proceso",
      },
      {
        text: "Modalidad de compra",
        value: "forma_contratacion",
      },
      {
        text: "Fecha de creación",
        value: "fecha_solicitado",
        align: "center",
      },
      {
        text: "Monto programado ($)",
        value: "monto_programado",
        align: "right",
      },
      {
        text: "Fondos certificado ($)",
        value: "monto_certificado",
        align: "right",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    certificaciones: [],
    filters: {
      page: 1,
      per_page: 10,
      pagination: "true",
      total_rows: null,
      codigo_proceso: null,
      id_estado: null,
      forma_contratacion: null,
    },
    modalidades: [],
    search: {
      codigo_proceso: null,
      id_estado: null,
      forma_contratacion: null,
    },
  }),
  computed: {
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
  },
  methods: {
    async getCertificacionesFondos() {
      const { data, status, headers } =
        await this.services.CertificacionFondo.getCertificacionesFondo({
          ...this.filters,
        });
      if (status === 200) {
        this.certificaciones = data;
        this.filters.page = Number(headers.page);
        this.filters.per_page = Number(headers.per_page);
        this.filters.total_rows = Number(headers.total_rows);
      }
    },
    async getCertificacionesFondosFilter() {
      this.filters.codigo_proceso = this.search.codigo_proceso;
      // this.filters.id_estado = this.search.id_estado
      this.filters.forma_contratacion = this.search.forma_contratacion;

      const response =
        await this.services.CertificacionFondo.getCertificacionesFondo(
          this.filters
        );

      if (response.status === 200) {
        this.certificaciones = response.data;
        this.filters.page = Number(response.headers.page);
        this.filters.per_page = Number(response.headers.per_page);
        this.filters.total_rows = Number(response.headers.total_rows);
      }
    },
    paginar(filtros) {
      const { pagina, cantidad_por_pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getCertificacionesFondos();
    },
    async getModalidades() {
      const { data, status } = await this.services.Paac.getModalidades();
      if (status === 200) {
        this.modalidades = data.forma_contratacion;
      }
    },
    verDetalleProceso(item) {
      window.open(
        `${location.origin}/certificacion-fondos/detalle/${item.id}`,
        "_blank"
      );
    },
    getStatusColor(item) {
      const porcentajeCertificacion = (item.monto_certificado * 100) / item.monto_programado;

      switch (true) {
        case porcentajeCertificacion < 40:
          return "error";
        case porcentajeCertificacion >= 60 && porcentajeCertificacion < 100:
          return "warning";
        case porcentajeCertificacion >= 100:
          return "success";
      }
    },
  },
  created() {
    setTimeout(() => {
      this.getCertificacionesFondos();
      this.getModalidades();
    }, 1500);
  },
};
</script>

<style></style>
